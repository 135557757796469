// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Img from 'gatsby-image'

import Gallery from '@bodhi-project/components/lib/gatsby/Gallery'
import Video from '@bodhi-project/components/lib/Video'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import StandardPage from '../components/wrappers/StandardPage'
import seoHelper from '../methods/seoHelper'

// import Link from '../components/Link'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React
const pageData = {
  pageTitle: 'Language Centers | Learning Wings Education System',
  nakedPageSlug: 'language-centers',
  pageAbstract:
    'Learning Wings Education Systems is the only authorized centre (IN364) in Punjab for conducting Qualifications for Cambridge Assessment English, a department of the University of Cambridge and part of Cambridge Assessment Group, which provides world’s leading range of qualifications for learners and teachers for English.',
}

const seoData = seoHelper(pageData)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    mobileLogo: file(relativePath: { eq: "logos/mobile-logo.png" }) {
      ...max300
    }
    desktopLogo: file(relativePath: { eq: "logos/desktop-logo.png" }) {
      ...max300
    }
    logo: file(relativePath: { eq: "logos/logo-square.png" }) {
      ...max300
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = props => (
  <StandardPage className="page" seoData={seoData} {...props}>
    <div className="small-default-container">
      <h1 class="shadow" data-shadow="Language Centers">
        Language Centers
      </h1>
      <div style={{ maxWidth: '50rem' }}>
        <div className="mask-p">
          <Video url="https://www.youtube.com/watch?v=nh0WAj1-7mo" />
        </div>
        <p>
          Learning Wings Education Systems is the only authorized centre (IN364)
          in Punjab for conducting Qualifications for Cambridge Assessment
          English, a department of the University of Cambridge and part of
          Cambridge Assessment Group, which provides world’s leading range of
          qualifications for learners and teachers for English. We are empowered
          to facilitate English Language training programs in all its
          developmental learning centres throughout India.
        </p>

        <p>Features of Cambridge Assessments English Qualifications:</p>
        <ul className="mask-p" style={{ paddingLeft: 40, listStyle: 'disc' }}>
          <li>
            Exams aligned in accordance with Common European Framework of
            Reference for Languages (CEFR) which describes language ability on a
            six-point scale, from A1 for beginners, upto C2 for those who have
            mastered a language
          </li>
          <li>
            Customized exams and training sessions to cater the needs of
            learning English for academics, professions and business
          </li>
          <li>Globally accepted certificates with lifetime validity</li>
          <li>Boost confidence and enhance communication skills</li>
          <li>Provide an edge for higher education and job placements</li>
        </ul>

        <p>Cambridge Assessments English @LWES:</p>
        <ul className="mask-p" style={{ paddingLeft: 40, listStyle: 'disc' }}>
          <li>
            Awarded the Best New Centre by Cambridge University UK for year 2015
          </li>
          <li>
            Currently working with more than 20 preparation centres across
            Punjab and hills of HP
          </li>
          <li>
            Partners with Cambridge University UK to support Language Program at
            Chandigarh University, Mohali
          </li>
        </ul>
      </div>
    </div>
  </StandardPage>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
